<template>
  <div>
    <v-row class="pa-0 ma-0 mx-2 mt-5">
      <v-col cols="12"
        ><v-card class="border-radius-lg">
          <v-row class="justify-center pt-3 pb-5 px-9">
            <div class="text-center">
              <v-avatar
                size="150"
                class="ma-3 text-uppercase text-dark bg-gradient-warning"
              >
                <v-img
                  v-if="!previewUrl"
                  :src="getImage(userInformations.avatar)"
                  :alt="userInformations.firstname"
                  v-on:error="onImgError"
                  :key="userInformations.avatar"
                />
                <v-img
                  v-else
                  :src="previewUrl"
                  :alt="userInformations.firstname"
                  v-on:error="onImgError"
                  :key="previewUrl"
                />
              </v-avatar>
              <div
                class="my-2 text-warning"
                style="cursor: pointer"
                @click="launchFilePicker"
              >
                Profilbild wählen
              </div>
              <input
                type="file"
                accept="image/*"
                style="display: none"
                ref="file"
                v-uploader
              />
            </div>
          </v-row> </v-card></v-col
    ></v-row>
    <v-row class="pa-0 ma-0 mx-2 mt-3">
      <v-col cols="12"
        ><v-card class="border-radius-lg pt-2">
          <v-row class="pa-0 ma-0 px-2">
            <v-col>
              <v-btn
                @click="selectType('provider')"
                elevation="0"
                :ripple="false"
                height="43"
                :class="
                  selectedType == 'provider'
                    ? 'font-weight-bold text-white btn-default bg-default py-2 px-6 me-2 mb-2 w-100'
                    : 'font-weight-bold btn-outlined py-2 px-6 me-2 mb-2 w-100'
                "
                :color="selectedType == 'provider' ? '#5e72e4' : ''"
                small
                ><span>Dienstleister</span>
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                @click="selectType('customer')"
                elevation="0"
                :ripple="false"
                height="43"
                :class="
                  selectedType == 'customer'
                    ? 'font-weight-bold text-white btn-default bg-default py-2 px-6 me-2 mb-2 w-100'
                    : 'font-weight-bold btn-outlined py-2 px-6 me-2 mb-2 w-100'
                "
                small
                ><span>Kunde</span>
              </v-btn>
            </v-col>
          </v-row>
        </v-card></v-col
      ></v-row
    >

    <v-row class="pa-0 ma-0 mx-2">
      <v-col cols="12"
        ><v-card class="border-radius-lg">
          <v-row
            class="align-center justify-space-between pa-0 ma-0 pa-3"
            style="cursor: pointer"
            @click="openCompanyInfoModal()"
          >
            <div class="mr-2 pt-1 text-h6 font-weight-bold text-typo">
              Firma
            </div>
            <div class="mr-3">
              <v-row class="pa-0 ma-0 align-center">
                <div class="mr-2 text-h6 font-weight-normal text-secondary">
                  {{ selectedCompany.name }} {{ selectedCompany.legalForm }}
                </div>
                <v-icon size="12">fas fa-chevron-right</v-icon>
              </v-row>
            </div>
          </v-row>
          <v-divider></v-divider>
          <v-row class="align-center justify-space-between pa-0 ma-0 pa-3">
            <div class="mr-2 pt-1 text-h6 font-weight-bold text-typo">
              Firmen-ID
            </div>
            <div class="mr-3">
              <v-row class="pa-0 ma-0 align-center">
                <div class="mr-2 text-h6 font-weight-normal text-secondary">
                  {{ selectedCompany.generatedId }}
                </div>
              </v-row>
            </div>
          </v-row>
        </v-card></v-col
      ></v-row
    >
    <v-row class="pa-0 ma-0 mx-2">
      <v-col cols="12"
        ><v-card class="border-radius-lg">
          <!-- <v-row class="align-center justify-space-between pa-0 ma-0 pa-3">
            <div class="mr-2 pt-1 text-h6 font-weight-bold text-typo">
              Vorname
            </div>
            <div class="mr-3">
              <v-row class="pa-0 ma-0 align-center">
                <div class="mr-2 text-h6 font-weight-normal text-secondary">
                  {{ userInformations.firstname }}
                </div>
                <v-icon size="12">fas fa-chevron-right</v-icon>
              </v-row>
            </div>
          </v-row> -->
          <v-form @submit.prevent lazy-validation>
            <div class="mx-4">
              <div class="pt-4">
                <span class="font-weight-bolder">Vorname</span>
              </div>
              <v-text-field
                placeholder="Max"
                class="rounded-lg mt-1"
                v-model="form.firstname"
                filled
                rounded
                dense
                :rules="formRules.nameRules"
                required
              ></v-text-field>
            </div>
            <v-divider></v-divider>

            <div class="mx-4">
              <div class="pt-4">
                <span class="font-weight-bolder">Nachname</span>
              </div>
              <v-text-field
                placeholder="Mustermann"
                class="rounded-lg mt-1"
                v-model="form.lastname"
                filled
                rounded
                dense
                :rules="formRules.nameRules"
                required
              ></v-text-field>
            </div>
            <v-divider></v-divider>

            <div class="mx-4">
              <div class="pt-4">
                <span class="font-weight-bolder">Telefon</span>
              </div>
              <v-text-field
                placeholder="+491234567890"
                class="rounded-lg mt-1"
                v-model="form.phone"
                type="phone"
                filled
                rounded
                dense
                :rules="formRules.phoneRules"
                required
              ></v-text-field>
            </div>
            <v-divider></v-divider>
            <div class="mx-4 pb-4">
              <div class="pt-4">
                <span class="font-weight-bolder">Passwort ändern</span>
              </div>
              <v-text-field
                hide-details
                outlined
                v-model="form.password"
                :rules="min8"
                background-color="rgba(255,255,255,.9)"
                color="rgba(0,0,0,.6)"
                light
                height="40"
                placeholder="Neues Passwort"
                type="password"
                class="input-style font-size-input border border-radius-md placeholder-lighter text-color-light mb-4 mt-2"
              >
              </v-text-field>
              <div class="d-sm-flex">
                <ul class="text-muted ps-6 mb-0">
                  <li :key="'validateSymbol' + validateSymbol">
                    <v-icon
                      class="mr-2"
                      :class="validateSymbol ? 'text-success' : 'text-danger'"
                      size="12"
                      >{{
                        validateSymbol ? "fa fa-check" : "fa fa-times"
                      }}</v-icon
                    >
                    <span class="text-sm">Ein Sonderzeichen </span>
                  </li>
                  <li :key="'validateLength' + validateLength">
                    <v-icon
                      class="mr-2"
                      :class="validateLength ? 'text-success' : 'text-danger'"
                      size="12"
                      >{{
                        validateLength ? "fa fa-check" : "fa fa-times"
                      }}</v-icon
                    >
                    <span class="text-sm">Mindestens 8 Zeichen</span>
                  </li>
                  <li :key="'validateNumber' + validateNumber">
                    <v-icon
                      class="mr-2"
                      :class="validateNumber ? 'text-success' : 'text-danger'"
                      size="12"
                      >{{
                        validateNumber ? "fa fa-check" : "fa fa-times"
                      }}</v-icon
                    >
                    <span class="text-sm"
                      >Eine Nummer (2 werden empfohlen)</span
                    >
                  </li>
                </ul>
              </div>
              <v-btn
                :disabled="
                  form.password != '' &&
                  (!validateSymbol || !validateLength || !validateNumber)
                "
                @click="updateUserInformations()"
                class="mt-3"
                elevation="0"
                :ripple="false"
                height="43"
                :class="
                  selectedType == 'provider'
                    ? 'font-weight-bold text-white btn-default bg-default py-2 px-6 me-2 mb-2 w-100'
                    : 'font-weight-bold btn-outlined text-white py-2 px-6 me-2 mb-2 w-100'
                "
                :color="'#333333'"
                small
                ><span v-if="!updatingAuth">Änderungen speichern</span>
                <v-progress-circular
                  v-else
                  indeterminate
                  color="white"
                ></v-progress-circular>
              </v-btn>
            </div>
          </v-form>
          <!-- <v-row class="align-center justify-space-between pa-0 ma-0 pa-3">
            <div class="mr-2 pt-1 text-h6 font-weight-bold text-warning">
              Passwort ändern
            </div>
            <div class="mr-3">
              <v-row class="pa-0 ma-0 align-center">
                <v-icon size="12">fas fa-chevron-right</v-icon>
              </v-row>
            </div>
          </v-row> -->
        </v-card></v-col
      ></v-row
    >
  </div>
</template>

<script>
import axiosAuth from "@/shared/configs/axios-auth";

export default {
  name: "Profile",
  components: {},
  directives: {
    uploader: {
      bind(el, binding, vnode) {
        el.addEventListener("change", (e) => {
          vnode.context.onFileChange(e);
          if (e.target.files[0] !== undefined) {
            vnode.context.fileInput = e.target.files[0];
            vnode.context.onFileChange(e);
          }
        });
      },
    },
  },
  computed: {
    validateSymbol() {
      let format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

      if (format.test(this.form.password)) {
        return true;
      } else {
        return false;
      }
    },
    validateLength() {
      return this.form.password.length >= 8;
    },
    validateNumber() {
      return /\d/.test(this.form.password);
    },
  },
  mounted() {
    this.form.firstname = this.userInformations.firstname;
    this.form.lastname = this.userInformations.lastname;
    this.form.phone = this.userInformations.phone;
  },
  data() {
    return {
      fileInput: "",
      previewUrl: "",
      base64: null,
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length <= 10 || "Name must be less than 10 characters",
      ],

      rules: [
        (value) => !!value || "Erforderlich.",
        (value) => (value && value.length >= 3) || "Min 3 Zeichen",
      ],
      min8: [(value) => (value && value.length >= 7) || "Min 8 Zeichen"],
      min6: [
        (value) =>
          (value && value.length > 5 && value.length < 7) || "6 Zeichen",
      ],
      form: { password: "", firstname: "", lastname: "" },
    };
  },
  methods: {
    launchFilePicker() {
      this.$refs.file.click();
    },
    onFileChange: function (event) {
      const file = this.fileInput;
      if (!file) {
        return false;
      }
      if (!file.type.match("image.*")) {
        return false;
      }
      const reader = new FileReader();
      const that = this;
      reader.onload = function (e) {
        that.previewUrl = e.target.result;
      };
      reader.readAsDataURL(file);
    },

    async updateUserInformations() {
      if (!this.form.firstname || !this.form.lastname) return;
      if (this.fileInput) {
        await axiosAuth
          .post("/users/upload", {
            image: this.previewUrl.split("base64,")[1],
          })
          .then((res) => {
            this.fileInput = null;
            this.previewUrl = null;
            if (this.form.password) {
              if (
                this.validateSymbol &&
                this.validateLength &&
                this.validateNumber
              ) {
                this.userInformations.firstname = this.form.firstname;
                this.userInformations.lastname = this.form.lastname;
                this.userInformations.phone = this.form.phone;
                this.userInformations.password = this.form.password;
                delete this.userInformations.avatar;
                this.$store.dispatch(
                  "updateUserInformations",
                  this.userInformations
                );
                this.$swal.fire("Änderungen gespeichert!", "", "success");
              } else {
                return;
              }
            } else {
              delete this.userInformations.avatar;
              this.userInformations.firstname = this.form.firstname;
              this.userInformations.lastname = this.form.lastname;
              this.userInformations.phone = this.form.phone;
              this.$store.dispatch(
                "updateUserInformations",
                this.userInformations
              );
              this.$swal.fire("Änderungen gespeichert!", "", "success");
            }
          })
          .catch((error) => {
            console.log(error.response);
          });
      } else {
        if (this.form.password) {
          if (
            this.validateSymbol &&
            this.validateLength &&
            this.validateNumber
          ) {
            this.userInformations.firstname = this.form.firstname;
            this.userInformations.lastname = this.form.lastname;
            this.userInformations.password = this.form.password;
            this.userInformations.phone = this.form.phone;
            this.$store.dispatch(
              "updateUserInformations",
              this.userInformations
            );
            this.$swal.fire("Änderungen gespeichert!", "", "success");
          } else {
            return;
          }
        } else {
          this.userInformations.firstname = this.form.firstname;
          this.userInformations.lastname = this.form.lastname;
          this.userInformations.phone = this.form.phone;
          this.$store.dispatch("updateUserInformations", this.userInformations);
          this.$swal.fire("Änderungen gespeichert!", "", "success");
        }
      }
    },
  },
};
</script>
